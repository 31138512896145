<div [ngClass]="{
    'navbar': true,
    'sticky': sticky,
    'standalone': router.url.includes('simulateur-panneau-solaire') || router.url.includes('recrutement/offres/postuler')
}" *ngIf="isMobile === false">
  <div class="navbar-component">
    <div *ngIf="!router.url.includes('simulateur-panneau-solaire') && !router.url.includes('recrutement/offres/postuler')" class="navbar-list">
      <Image image="logo" type="icon" width="158px" (click)="this.router.navigate(['/'])"></Image>
      <ul>
        <li>
          <ButtonAtom
          [text]="'PARTICULIERS'"
          [text2]="'Nos offres solaires'"
          [typeRow]="'doubleRow'"
          [style]="'clear'"
          color="dark"
        >
        </ButtonAtom>
        
        
          
  
  <!-- NAVBAR-LINKS 1 -->
          <div class="navbar-links first">
            <div>
              <ul>
                <li>
                  <a [routerLink]="'/installer-panneaux-solaires'">
                    <div>
                      <Image type="icon" width="36px" image="solar-panels"></Image>
                    </div>
                    <div>
                      <ParagraphAtom text="Produire mon électricité" color="dark" weight="semi-bold" size="intermediate"></ParagraphAtom>
                      <ParagraphAtom text="Installation de panneaux photovoltaïques" color="dark" weight="regular" size="small"></ParagraphAtom>
                    </div>
                  </a>
                </li>
                <li>
                  <a [routerLink]="'/installer-borne-de-recharge-solaire'">
                    <div>
                      <Image type="icon" width="36px" image="charging-station"></Image>
                    </div>
                    <div>
                      <ParagraphAtom text="Recharger mon véhicule électrique" color="dark" weight="semi-bold" size="intermediate"></ParagraphAtom>
                      <ParagraphAtom text="Installation d’une borne de recharge solaire" color="dark" weight="regular" size="small"></ParagraphAtom>
                    </div>
                  </a>
                </li>
                <li  >
                  <a [routerLink]="'/simulateur-panneau-solaire'">
                      <div>
                        <Image type="icon" width="36px" image="calculator"></Image>
                      </div>
                      <div>
                        <ParagraphAtom text="Calculer vos économies" color="dark" weight="semi-bold" size="intermediate"></ParagraphAtom>
                        <ParagraphAtom text="Simulez gratuitement votre installation" color="dark" weight="regular" size="small"></ParagraphAtom>
                      </div>
                  </a>
                </li>
              </ul>
              <div class="image-domotique card-methode-monabee" style="width:100%">
                  <Image type="image" borderRadius="radius" width="288px" height="143px" imageCloudinary="misc/page_domotique_rcvbfx.jpg" ></Image>
                  <div class="card-methode-monabee-paragraph">
                    <ParagraphAtom text="Piloter vos équipements et votre consommation en toute simplicité" color="dark" weight="medium-weight" size="medium" align="left"></ParagraphAtom>
                  </div>
                  <div class="card-methode-monabee-button">
                    <ButtonAtom  href="domotique-solaire-monitoring-photovoltaique/"  size="small" text="Découvrir notre méthode" [style]="'stroked'" color="light"></ButtonAtom>
                  </div>
              </div>
            </div>
          </div>
        </li>
        <li>
          <ButtonAtom
          [text]="'PROFESSIONNELS'"
          [text2]="'Nos offres solaires'"
          [typeRow]="'doubleRow'"
          [style]="'clear'"
          color="dark"
          (click)="this.router.navigate(['/installer-panneaux-solaires-professionnels'])"
        >
        </ButtonAtom>
        
        </li>
        <li>
          <ButtonAtom text="S'informer" [style]="'clear'" color="dark-small" ></ButtonAtom>
          <!-- NAVBAR-LINKS-2 -->
          <div class="navbar-links second">
            <ul>
              <li>
                <a [routerLink]="'/blog'">
                  <div>
                    <Image type="icon" width="36px" image="articles"></Image>
                  </div>
                  <div>
                    <ParagraphAtom text="Nos actualités et conseils" color="dark" weight="semi-bold" size="intermediate"></ParagraphAtom>
                    <ParagraphAtom text="Le solaire n’aura bientôt plus de secret pour vous" color="dark" weight="regular" size="small"></ParagraphAtom>
                  </div>
                </a>
              </li>
              <li>
                <a [routerLink]="'/conseils/aides-financieres'">
                  <div>
                    <Image type="icon" width="36px" image="piggy-bank-small"></Image>
                  </div>
                  <div>
                    <ParagraphAtom text="Les aides financières" color="dark" weight="semi-bold" size="intermediate"></ParagraphAtom>
                    <ParagraphAtom text="Quelles sont-elles ? On vous dit tout" color="dark" weight="regular" size="small"></ParagraphAtom>
                  </div>
                </a>
              </li>
              <li>
                <a [routerLink]="'/guides'">
                  <div>
                    <Image type="icon" width="36px" image="guides"></Image>
                  </div>
                  <div>
                    <ParagraphAtom text="Nos guides" color="dark" weight="semi-bold" size="intermediate"></ParagraphAtom>
                    <ParagraphAtom text="Les indispensables pour vous guider pas-à-pas" color="dark" weight="regular" size="small"></ParagraphAtom>
                  </div>
                </a>
              </li>
              <li>
                <a [routerLink]="'/temoignage-client'">
                  <div>
                    <Image type="icon" width="36px" image="avatar"></Image>
                  </div>
                  <div>
                    <ParagraphAtom text="Témoignages" color="dark" weight="semi-bold" size="intermediate"></ParagraphAtom>
                    <ParagraphAtom text="Ils témoignent pour nous et pour vous" color="dark" weight="regular" size="small"></ParagraphAtom>
                  </div>
                </a>
              </li>
              <li>
                <a [routerLink]="'/faq'">
                  <div>
                    <Image type="icon" width="36px" image="question"></Image>
                  </div>
                  <div>
                    <ParagraphAtom text="FAQ" color="dark" weight="semi-bold" size="intermediate"></ParagraphAtom>
                    <ParagraphAtom text="Vous avez une question ? On y répond" color="dark" weight="regular" size="small"></ParagraphAtom>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </li>
        <li>
          <ButtonAtom text="Nous découvrir" [style]="'clear'" color="dark-small"></ButtonAtom>
          <!-- NAVBAR-LINKS-3 -->
          <div class="navbar-links third">
            <ul>
              <li>
                <a [routerLink]="'/entreprise'">
                  <div>
                    <Image type="icon" width="36px" image="hive"></Image>
                  </div>
                  <div>
                    <ParagraphAtom text="Nos engagements" color="dark" weight="semi-bold" size="intermediate"></ParagraphAtom>
                    <ParagraphAtom text="Monabee, une entreprise française 100% engagée" color="dark" weight="regular" size="small"></ParagraphAtom>
                  </div>
                </a>
              </li>
              <li>
                <a [routerLink]="'/histoire'">
                  <div>
                    <Image type="icon" width="36px" image="back-in-time"></Image>
                  </div>
                  <div>
                    <ParagraphAtom text="Notre histoire" color="dark" weight="semi-bold" size="intermediate"></ParagraphAtom>
                    <ParagraphAtom text="Monabee, spécialiste de l’énergie solaire depuis 2012" color="dark" weight="regular" size="small"></ParagraphAtom>
                  </div>
                </a>
              </li>
              <li>
                <a [routerLink]="'/recrutement'">
                  <div>
                    <Image type="icon" width="36px" image="hiring"></Image>
                  </div>
                  <div>
                    <ParagraphAtom text="Nous rejoindre" color="dark" weight="semi-bold" size="intermediate"></ParagraphAtom>
                    <ParagraphAtom text="Rejoignez la ruche" color="dark" weight="regular" size="small"></ParagraphAtom>
                  </div>
                </a>
              </li>
              <li>
                <a [routerLink]="'/presse'">
                  <div>
                    <Image type="icon" width="36px" mobileWidth="36px" image="press"></Image>
                  </div>
                  <div>
                    <ParagraphAtom text="Monabee dans la presse" color="dark" weight="semi-bold" size="intermediate"></ParagraphAtom>
                    <ParagraphAtom text="Ils parlent de nous !" color="dark" weight="regular" size="nano"></ParagraphAtom>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>

    <div *ngIf="!router.url.includes('simulateur-panneau-solaire') && !router.url.includes('recrutement/offres/postuler')" class="navbar-btn-container">
      <ButtonAtom href="/contact" size="small" text="Contact" [style]="'stroked'" color="light"></ButtonAtom>
      <ButtonAtom href="/simulateur-panneau-solaire" size="small" text="Simulateur" [style]="'basic'" color="yellow"></ButtonAtom>
    </div>

    <!-- SIMULATOR NAVBAR -->
    <div *ngIf="router.url.includes('simulateur-panneau-solaire')" class="navbar-list-simulator">
      <Image image="logo" type="image" width="158px" href="/" (click)="this.router.navigate(['/'])"></Image>
      <TitleAtom type="h1" size="tiny" weight="medium-weight" text="Simulateur de panneaux solaires" ></TitleAtom>
    </div>

    <!-- APPLYING NAVBAR -->
    <div *ngIf="router.url.includes('recrutement/offres/postuler')" class="navbar-list-simulator">
      <Image image="logo" type="image" width="158px" (click)="this.router.navigate(['/'])"></Image>
      <ButtonAtom [style]="'clear'" color="dark" iconRight="close-dark" text="Fermer" href="/recrutement"></ButtonAtom>
    </div>

  </div>
</div>


<!-- MOBILE NAVBAR -->
<div [ngClass]="this.sticky === true ? 'sticky' : ''" class="navbar-component-mobile" *ngIf="isMobile === true">

  <div class="navbar-mobile-main">
    <Image image="logo" type="image" width="131px" mobileWidth="131px" (click)="this.router.navigate(['/'])"></Image>
    <Image image="menu" type="icon" width="24px" (click)="openMenu()"></Image>
  </div>

</div>

<!-- MOBILE MENU -->
<div *ngIf="this.menuIsOpen === true" class="navbar-mobile-menu">
  <div class="menu-header">
    <TitleAtom size="small" weight="semi-bold" text="Menu"></TitleAtom>
    <Image image="close-dark" type="icon" width="14px" mobileWidth="14px" (click)="closeMenu()"></Image>
  </div>
  <ul class="menu-navigation">
    <li>
      <div (click)="openOffers()">
        <ButtonAtom [style]="'clear'" color="dark" text="Particuliers - Nos offres solaires"></ButtonAtom>
        <Image type="icon" image="{{this.offersIsOpen === true ? 'arrow-top-dark' : 'arrow-bottom-dark'}}" width="9px" mobileWidth="9px"></Image>
      </div>
      <ul class="offers-list" *ngIf="this.offersIsOpen === true">
        <li>
          <a (click)="closeMenu()" [routerLink]="'/installer-panneaux-solaires'">
            <Image type="icon" width="36px" mobileWidth="36px" image="solar-panel-blue"></Image>
            <div>
              <ParagraphAtom text="Produire mon électricité" color="dark" weight="semi-bold" size="intermediate"></ParagraphAtom>
              <ParagraphAtom text="Installation de panneaux photovoltaïques" color="dark" weight="regular" size="nano"></ParagraphAtom>
            </div>
          </a>
        </li>
        <li>
          <a (click)="closeMenu()" [routerLink]="'/installer-borne-de-recharge-solaire'">
            <Image type="icon" width="36px" mobileWidth="36px" image="charging-station"></Image>
            <div>
              <ParagraphAtom text="Recharger mon véhicule électrique" color="dark" weight="semi-bold" size="intermediate"></ParagraphAtom>
              <ParagraphAtom text="Installation d’une borne de recharge solaire" color="dark" weight="regular" size="nano"></ParagraphAtom>
            </div>
          </a>
        </li>
        <li>
          <a (click)="closeMenu()" [routerLink]="'/simulateur-panneau-solaire'">
            <Image type="icon" width="36px" mobileWidth="36px" image="calculator"></Image>
            <div>
              <ParagraphAtom text="Calculer vos économies" color="dark" weight="semi-bold" size="intermediate"></ParagraphAtom>
              <ParagraphAtom text="Simulez gratuitement votre installation" color="dark" weight="regular" size="nano"></ParagraphAtom>
            </div>
          </a>
        </li>
      </ul>
  

      <div class="image-domotique card-methode-monabee" *ngIf="this.offersIsOpen === true">
        <Image type="image" borderRadius="radius"    imageCloudinary="misc/page_domotique_rcvbfx.jpg" ></Image>
        <div class="card-methode-monabee-paragraph">
          <ParagraphAtom text="Piloter vos équipements et votre consommation en toute simplicité" color="dark" weight="medium-weight" size="medium" align="left"></ParagraphAtom>
        </div>
        <div class="card-methode-monabee-button">
          <ButtonAtom  href="domotique-solaire-monitoring-photovoltaique/"  size="small" text="Découvrir notre méthode" [style]="'stroked'" color="light"></ButtonAtom>
        </div>
    </div>
    </li>
    <li>
      <a (click)="closeMenu()" [routerLink]="'/installer-panneaux-solaires-professionnels'">
        <div>
          <ButtonAtom [style]="'clear'" color="dark" text="Professionnels - Nos offres solaires"></ButtonAtom>
          <Image type="icon" image="arrow-bottom-dark" width="9px" mobileWidth="9px"></Image>
        </div>
      </a>
    </li>
    <li>
      <div (click)="openInformed()">
        <ButtonAtom [style]="'clear'" color="dark" text="S’informer"></ButtonAtom>
        <Image type="icon" image="{{this.informedIsOpen === true ? 'arrow-top-dark' : 'arrow-bottom-dark'}}" width="9px" mobileWidth="9px"></Image>
      </div>
      <ul *ngIf="this.informedIsOpen === true">
        <li>
          <a (click)="closeMenu()" [routerLink]="'/blog'">
            <Image type="icon" width="36px" mobileWidth="36px" image="articles-blue"></Image>
            <div>
              <ParagraphAtom text="Nos actualités et conseils" color="dark" weight="semi-bold" size="intermediate"></ParagraphAtom>
              <ParagraphAtom text="Le solaire n’aura bientôt plus de secret pour vous" color="dark" weight="regular" size="nano"></ParagraphAtom>
            </div>
          </a>
        </li>
        <li>
          <a (click)="closeMenu()" [routerLink]="'/conseils/aides-financieres'">
            <Image type="icon" width="36px" mobileWidth="36px" image="piggy-bank-small"></Image>
            <div>
              <ParagraphAtom text="Les aides financières" color="dark" weight="semi-bold" size="intermediate"></ParagraphAtom>
              <ParagraphAtom text="Quelles sont-elles ? On vous dit tout" color="dark" weight="regular" size="nano"></ParagraphAtom>
            </div>
          </a>
        </li>
        <li>
          <a (click)="closeMenu()" [routerLink]="'/guides'">
            <Image type="icon" width="36px" mobileWidth="36px" image="guides"></Image>
            <div>
              <ParagraphAtom text="Nos guides" color="dark" weight="semi-bold" size="intermediate"></ParagraphAtom>
              <ParagraphAtom text="Les indispensables pour vous guider pas-à-pas" color="dark" weight="regular" size="nano"></ParagraphAtom>
            </div>
          </a>
        </li>
        <li>
          <a (click)="closeMenu()" [routerLink]="'/temoignage-client'">
            <Image type="icon" width="36px" mobileWidth="36px" image="avatar"></Image>
            <div>
              <ParagraphAtom text="Témoignages" color="dark" weight="semi-bold" size="intermediate"></ParagraphAtom>
              <ParagraphAtom text="Ils témoignent pour nous et pour vous" color="dark" weight="regular" size="nano"></ParagraphAtom>
            </div>
          </a>
        </li>
        <li>
          <a (click)="closeMenu()" [routerLink]="'/faq'">
            <Image type="icon" width="36px" mobileWidth="36px" image="question"></Image>
            <div>
              <ParagraphAtom text="FAQ" color="dark" weight="semi-bold" size="intermediate"></ParagraphAtom>
              <ParagraphAtom text="Vous avez une question ? On y répond" color="dark" weight="regular" size="nano"></ParagraphAtom>
            </div>
          </a>
        </li>
      </ul>
    </li>
    <li>
      <div (click)="openAbout()">
        <ButtonAtom [style]="'clear'" color="dark" text="Nous découvrir"></ButtonAtom>
        <Image type="icon" image="{{this.aboutIsOpen === true ? 'arrow-top-dark' : 'arrow-bottom-dark'}}" width="9px" mobileWidth="9px"></Image>
      </div>
      <ul *ngIf="this.aboutIsOpen === true">
        <li>
          <a (click)="closeMenu()" [routerLink]="'/entreprise'">
            <Image type="icon" width="36px" mobileWidth="36px" image="hive"></Image>
            <div>
              <ParagraphAtom text="Nos engagements" color="dark" weight="semi-bold" size="intermediate"></ParagraphAtom>
              <ParagraphAtom text="Monabee, une entreprise française 100% engagée" color="dark" weight="regular" size="nano"></ParagraphAtom>
            </div>
          </a>
        </li>
        <li>
          <a (click)="closeMenu()" [routerLink]="'/histoire'">
            <Image type="icon" width="36px" mobileWidth="36px" image="back-in-time"></Image>
            <div>
              <ParagraphAtom text="Notre histoire" color="dark" weight="semi-bold" size="intermediate"></ParagraphAtom>
              <ParagraphAtom text="Monabee, spécialiste de l’énergie solaire depuis 2012" color="dark" weight="regular" size="nano"></ParagraphAtom>
            </div>
          </a>
        </li>
        <li>
          <a (click)="closeMenu()" [routerLink]="'/recrutement'">
            <Image type="icon" width="36px" mobileWidth="36px" image="hiring"></Image>
            <div>
              <ParagraphAtom text="Nous rejoindre" color="dark" weight="semi-bold" size="intermediate"></ParagraphAtom>
              <ParagraphAtom text="Rejoignez la ruche" color="dark" weight="regular" size="nano"></ParagraphAtom>
            </div>
          </a>
        </li>
        <li>
          <a (click)="closeMenu()" [routerLink]="'/presse'">
            <Image type="icon" width="36px" mobileWidth="36px" image="press"></Image>
            <div>
              <ParagraphAtom text="Monabee dans la presse" color="dark" weight="semi-bold" size="intermediate"></ParagraphAtom>
              <ParagraphAtom text="Ils parlent de nous !" color="dark" weight="regular" size="nano"></ParagraphAtom>
            </div>
          </a>
        </li>
      </ul>
    </li>
  </ul>
  <div class="menu-btn">
    <ButtonAtom (click)="closeMenu()" [routerLink]="'/simulateur-panneau-solaire'" [style]="'basic'" color="yellow" text="Simulateur"></ButtonAtom>
    <ButtonAtom (click)="closeMenu()" [routerLink]="'/contact'" [style]="'stroked'" color="light" size="default" text="Contact"></ButtonAtom>
    <ButtonAtom (click)="closeMenu()" icon="account-dark" [style]="'clear'" color="dark" text="Espace client" href="https://monabee.net" target="_blank"></ButtonAtom>
  </div>
</div>
