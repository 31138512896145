// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  googleAnalyticsId: '',
  googleTagId: '',
  assetsUrl: '',
  url: "https://d3u5slsagqox11.cloudfront.net",
  api: 'https://q7ovjhhthf.execute-api.eu-west-3.amazonaws.com/latest',
  googleApiKey: 'AIzaSyBkhDv6P60cY0fWeVLEjlU3VfBEvLxEEw4',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
