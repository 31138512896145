<div class="offer-charging-container">
  <div class="announcement-container">
    <Announcement></Announcement>
  </div>

  <!-- HEROBANNER -->
  <div class="offer-herobanner">

    <!-- NAVBAR -->
    <div class="navbar-container">
      <Navbar></Navbar>
      <Breadcrumb color="light" [links]="[{title: 'Recharger mon véhicule électrique'}]"></Breadcrumb>
    </div>

    <div id="offer-charging-form" class="offer-herobanner-content">
      <div id="chargingOffer" class="content">
        <TitleAtom [mobileSize]="18" color="yellow" weight="semi-bold" size="small" [text]="cmsData!.data.herobanner_title_yellow"></TitleAtom>
        <TitleAtom type="h1" color="light" weight="semi-bold" size="x-large" [text]="cmsData!.data.herobanner_title_h1"></TitleAtom>
        <ParagraphAtom color="light" weight="regular" size="intermediate" [text]="cmsData!.data.herobanner_text"></ParagraphAtom>
        <div class="glass-text" *ngIf="cmsData!.data.herobanner_text_framed">
          <ParagraphAtom color="light" weight="regular" size="intermediate" [text]="cmsData!.data.herobanner_text_framed"></ParagraphAtom>
        </div>
        <ParagraphAtom color="light" weight="regular" size="small" [text]="cmsData!.data.herobanner_subtitle_framed"></ParagraphAtom>
        <TitleAtom [mobileSize]="16" color="light" weight="semi-bold" size="tiny" [text]="cmsData!.data.herobanner_certification_title"></TitleAtom>
        <div>
          <div class="offer-certification">
            <Image width="91px" type="image" [imageExternal]="cmsData!.data.herobanner_rge_img.url"></Image>
            <div>
              <Image width="24px" type="icon" image="check-yellow"></Image>
              <ParagraphAtom color="light" size="intermediate" weight="regular" [text]="cmsData!.data.herobanner_rge_text"></ParagraphAtom>
            </div>
          </div>
          <div class="offer-certification">
            <Image width="81px" type="image" [imageExternal]="cmsData!.data.herobanner_authorization_img.url"></Image>
            <div>
              <Image width="24px" type="icon" image="check-yellow"></Image>
              <ParagraphAtom color="light" size="intermediate" weight="regular" [text]="cmsData!.data.herobanner_authorization_text"></ParagraphAtom>
            </div>
          </div>
        </div>
      </div>
      <ContactMessage [hideRadioBtn]="true" [disableUserTypeChange]="true" [title]="cmsData!.data.herobanner_form_title" [btnText]="cmsData!.data.herobanner_form_btn_text" userType="particulier"></ContactMessage>
    </div>
  </div>

  <!-- ADVANTAGES -->
  <div id="chargingAdvantages" class="offer-advantages" *ngIf="cmsData?.data?.advantages_title?.trim() !== ''">
    <TitleAtom [mobileSize]="22" type="h2" align="center" color="dark" size="x-large" weight="semi-bold" [text]="cmsData!.data.advantages_title"></TitleAtom>
    <ul>
      <li *ngIf="cmsData!.data.advantages_first_img?.url?.trim() !== '' ||
               cmsData!.data.advantages_first_title?.trim() !== '' ||
               cmsData!.data.advantages_first_text_one?.trim() !== '' ||
               cmsData!.data.advantages_first_text_two?.trim() !== '' ||
               cmsData!.data.advantages_first_text_three?.trim() !== ''">
        <Image type="image" width="600px" mobileWidth="100%" borderRadius="radius" [imageExternal]="cmsData!.data.advantages_first_img.url"></Image>
        <div class="offer-advantages-safe">
          <TitleAtom [mobileSize]="18" type="h3" color="dark" weight="semi-bold" size="large" [text]="cmsData!.data.advantages_first_title"></TitleAtom>
          <div *ngIf="cmsData!.data.advantages_first_text_one?.trim() !== ''">
            <ParagraphAtom color="dark" weight="regular" size="intermediate" [text]="cmsData!.data.advantages_first_text_one"></ParagraphAtom>
          </div>
          <div *ngIf="cmsData!.data.advantages_first_text_two?.trim() !== ''">
            <ParagraphAtom color="dark" weight="regular" size="intermediate" [text]="cmsData!.data.advantages_first_text_two"></ParagraphAtom>
          </div>
          <div *ngIf="cmsData!.data.advantages_first_text_three?.trim() !== ''">
            <ParagraphAtom color="dark" weight="regular" size="intermediate" [text]="cmsData!.data.advantages_first_text_three"></ParagraphAtom>
          </div>
        </div>
      </li>

      <li *ngIf="cmsData!.data.advantages_second_img?.url?.trim() !== '' ||
               cmsData!.data.advantages_second_title?.trim() !== '' ||
               cmsData!.data.advantages_second_text?.trim() !== '' ||
               cmsData!.data.advantages_second_framed_text?.trim() !== ''">
        <div class="offer-advantages-safe">
          <TitleAtom [mobileSize]="18" type="h3" color="dark" weight="semi-bold" size="large" [text]="cmsData!.data.advantages_second_title"></TitleAtom>
          <div *ngIf="cmsData!.data.advantages_second_text?.trim() !== ''">
            <ParagraphAtom color="dark" weight="regular" size="intermediate" [text]="cmsData!.data.advantages_second_text"></ParagraphAtom>
          </div>
          <div class="offer-warning" *ngIf="cmsData!.data.advantages_second_framed_text?.trim() !== ''">
            <Image type="icon" size="32px" image="warning-orange"></Image>
            <div>
              <ParagraphAtom color="dark" weight="regular" size="intermediate" [text]="cmsData!.data.advantages_second_framed_text"></ParagraphAtom>
            </div>
          </div>
        </div>
        <Image type="image" width="600px" mobileWidth="100%" borderRadius="radius" [imageExternal]="cmsData!.data.advantages_second_img.url"></Image>
      </li>

      <li *ngIf="cmsData!.data.advantages_third_img.url.trim() !== '' ||
               cmsData!.data.advantages_third_title.trim() !== '' ||
               cmsData!.data.advantages_third_text.trim() !== '' ||
               cmsData!.data.advantages_third_list.length > 0">
        <Image type="image" width="600px" mobileWidth="100%" borderRadius="radius" [imageExternal]="cmsData!.data.advantages_third_img.url"></Image>
        <div class="offer-advantages-safe">
          <TitleAtom [mobileSize]="18" type="h3" color="dark" weight="semi-bold" size="large" [text]="cmsData!.data.advantages_third_title"></TitleAtom>
          <ParagraphAtom color="dark" weight="regular" size="intermediate" [text]="cmsData!.data.advantages_third_text"></ParagraphAtom>

          <ul class="advantage-list" *ngIf="cmsData!.data.advantages_third_list.length > 0">
            <li class="list-item" *ngFor="let item of cmsData!.data.advantages_third_list">
              <div>
                <ParagraphAtom color="dark" weight="semi-bold" size="intermediate" text="•"></ParagraphAtom>
              </div>
              <div>
                <ParagraphAtom color="dark" weight="regular" size="intermediate" [text]="item.advantages_list_text"></ParagraphAtom>
              </div>
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </div>

  <!-- 5 STEPS -->
  <div id="chargingSteps" class="offer-steps" *ngIf="cmsData!.data && (cmsData!.data.steps_title?.trim() !== '' || cmsData!.data.steps_subtitle?.trim() !== '' || cmsData!.data.step_one_title?.trim() !== '' || cmsData!.data.step_one_text?.trim() !== '' || cmsData!.data.step_two_title?.trim() !== '' || cmsData!.data.step_two_subtitle?.trim() !== '' || cmsData!.data.step_three_title?.trim() !== '' || cmsData!.data.step_three_subtitle?.trim() !== '' || cmsData!.data.step_four_title?.trim() !== '' || cmsData!.data.step_four_subtitle?.trim() !== '' || cmsData!.data.step_five_title?.trim() !== '' || cmsData!.data.step_five_subtitle?.trim() !== '' || cmsData!.data.step_six_title.trim() !== '' || cmsData!.data.step_six_subtitle.trim() !== '' || cmsData!.data.steps_btn_text?.trim() !== '')">>
    <Image *ngIf="currentWidth > 1000" type="icon" class="offers-top-left" image="offers-top-left"></Image>
    <Image *ngIf="currentWidth > 1000" type="icon" class="offers-top-right" image="offers-top-right"></Image>
    <Image *ngIf="currentWidth > 1000" type="icon" class="offers-bottom-left" image="offers-bottom-left"></Image>
    <Image *ngIf="currentWidth > 1000" type="icon" class="offers-bottom-right" image="offers-bottom-right"></Image>

    <Image *ngIf="currentWidth < 1000" type="icon" class="offers-top-left" image="offers-top-left-mobile"></Image>
    <Image *ngIf="currentWidth < 1000" type="icon" class="offers-top-right" image="offers-top-right-mobile"></Image>
    <Image *ngIf="currentWidth < 1000" type="icon" class="offers-bottom-left" image="offers-bottom-left-mobile"></Image>
    <Image *ngIf="currentWidth < 1000" type="icon" class="offers-bottom-right" image="offers-bottom-right-mobile"></Image>

    <TitleAtom class="offers-steps-title" [mobileSize]="22" align="center" type="h2" color="dark" weight="semi-bold" size="x-large" [text]="cmsData!.data.steps_title"></TitleAtom>
    <ParagraphAtom class="offer-steps-paragraph" align="center" color="dark" weight="regular" size="intermediate" [text]="cmsData!.data.steps_subtitle"></ParagraphAtom>
    <ul>
      <li>
        <CardInline [style]="'minimal'" image="step-1" [title]="cmsData!.data.step_one_title" [text]="cmsData!.data.step_one_text" [btnText]="cmsData!.data.step_one_btn_text" [href]="cmsData!.data.step_one_btn_link.url"></CardInline>
      </li>
      <li>
        <CardInline [style]="'minimal'" image="step-2" [title]="cmsData!.data.step_two_title" [text]="cmsData!.data.step_two_subtitle"></CardInline>
      </li>
      <li>
        <CardInline [style]="'minimal'" image="step-3" [title]="cmsData!.data.step_three_title" [text]="cmsData!.data.step_four_subtitle"></CardInline>
      </li>
      <li>
        <CardInline [style]="'minimal'" image="step-4" [title]="cmsData!.data.step_four_title" [text]="cmsData!.data.step_four_subtitle"></CardInline>
      </li>
      <li>
        <CardInline [style]="'minimal'" image="step-5" [title]="cmsData!.data.step_five_title" [text]="cmsData!.data.step_five_subtitle"></CardInline>
      </li>
      <li>
        <CardInline [style]="'minimal'" image="step-6" [title]="cmsData!.data.step_six_title" [text]="cmsData!.data.step_six_subtitle"></CardInline>
      </li>
    </ul>
    <ButtonAtom size="default" fragmentLink="offer-charging-form" [style]="'stroked'" color="light" [text]="cmsData!.data.steps_btn_text" (click)="scrollTop()"></ButtonAtom>
  </div>

  <!-- SETUP -->
  <div id="chargingSetup" class="offer-setup" *ngIf="cmsData?.data &&
  (cmsData!.data.slider_title?.trim() !== '' ||
   cmsData!.data.slider_step_one_title?.trim() !== '' ||
   cmsData!.data.slider_step_one_subtitle?.trim() !== '' ||
   cmsData!.data.slider_step_two_title?.trim() !== '' ||
   cmsData!.data.slider_step_two_subtitle?.trim() !== '' ||
   cmsData!.data.slider_step_three_title?.trim() !== '' ||
   cmsData!.data.slider_step_three_subtitle?.trim() !== '')">
      <TitleAtom [mobileSize]="22" align="center" type="p" color="dark" weight="semi-bold" size="x-large" [text]="cmsData!.data.slider_title"></TitleAtom>

      <!-- STEP 1 -->
      <div class="slider-step1" *ngIf="this.stepCount === 1">

      <ng-lottie
        [width]="isMobile ? '100%' : '1220px'"
        [options]="{path: isMobile ? 'assets/animations/panneau-solaire-1-mobile/animation.json' : 'assets/animations/panneau-solaire-1/animation.json'}">
      </ng-lottie>

      <div class="slider-container">


        <div class="slider-title">
          <div class="slider-btn-left">
            <ButtonAtom [style]="'clear'" icon="arrow-left-dark-tiny" (click)="previousStep()"></ButtonAtom>
          </div>
          <TitleAtom align="center" type="h3" color="dark" size="large" weight="semi-bold" [text]="cmsData!.data.slider_step_one_title"></TitleAtom>
          <div class="slider-btn-right">
            <ButtonAtom [style]="'clear'" icon="arrow-right-dark-tiny" (click)="nextStep()"></ButtonAtom>
          </div>

        </div>

      </div>
      <div class="slider-content">
        <ParagraphAtom align="center" color="dark" size="intermediate" weight="regular" [text]="cmsData!.data.slider_step_one_subtitle"></ParagraphAtom>
      </div>

      <ul>
        <li>
          <a href="javascript:void(0);" (click)="goToStep(1)">
            <Image type="icon" width="8px" image="ellipsis-yellow"></Image>
          </a>
        </li>
        <li>
          <a href="javascript:void(0);" (click)="goToStep(2)">
            <Image type="icon" width="8px" image="ellipsis-grey"></Image>
          </a>
        </li>
        <li>
          <a href="javascript:void(0);" (click)="goToStep(3)">
            <Image type="icon" width="8px" image="ellipsis-grey"></Image>
          </a>
        </li>
      </ul>
    </div>

      <!-- STEP 3 -->
      <div class="slider-step1" *ngIf="this.stepCount === 2">
        <ng-lottie
          [width]="isMobile ? '100%' : '1220px'"
          [options]="{path: isMobile ? 'assets/animations/panneau-solaire-2-mobile/animation.json' : 'assets/animations/panneau-solaire-2/animation.json'}">
        </ng-lottie>

        <div class="slider-container">


          <div class="slider-title">
            <div class="slider-btn-left">
              <ButtonAtom [style]="'clear'" icon="arrow-left-dark-tiny" (click)="previousStep()"></ButtonAtom>
            </div>
            <TitleAtom align="center" type="h3" color="dark" size="large" weight="semi-bold" [text]="cmsData!.data.slider_step_three_title"></TitleAtom>
            <div class="slider-btn-right">
              <ButtonAtom [style]="'clear'" icon="arrow-right-dark-tiny" (click)="nextStep()"></ButtonAtom>
            </div>

          </div>

        </div>

        <div class="slider-content">
          <ParagraphAtom align="center" color="dark" size="intermediate" weight="regular" [text]="cmsData!.data.slider_step_three_subtitle"></ParagraphAtom>
        </div>

        <ul>
          <li>
            <a href="javascript:void(0);" (click)="goToStep(1)">
              <Image type="icon" width="8px" image="ellipsis-grey"></Image>
            </a>
          </li>
          <li>
            <a href="javascript:void(0);" (click)="goToStep(2)">
              <Image type="icon" width="8px" image="ellipsis-yellow"></Image>
            </a>
          </li>
          <li>
            <a href="javascript:void(0);" (click)="goToStep(3)">
              <Image type="icon" width="8px" image="ellipsis-grey"></Image>
            </a>
          </li>
        </ul>
      </div>

      <!-- STEP 2 -->
      <div class="slider-step1" *ngIf="this.stepCount === 3">
        <ng-lottie
          [width]="isMobile ? '100%' : '1220px'"
          [options]="{path: isMobile ? 'assets/animations/panneau-solaire-3-mobile/animation.json' : 'assets/animations/panneau-solaire-3/animation.json'}">
        </ng-lottie>

        <div class="slider-container">


          <div class="slider-title">
            <div class="slider-btn-left">
              <ButtonAtom [style]="'clear'" icon="arrow-left-dark-tiny" (click)="previousStep()"></ButtonAtom>
            </div>
            <TitleAtom align="center" type="h3" color="dark" size="large" weight="semi-bold" [text]="cmsData!.data.slider_step_three_title"></TitleAtom>
            <div class="slider-btn-right">
              <ButtonAtom [style]="'clear'" icon="arrow-right-dark-tiny" (click)="nextStep()"></ButtonAtom>
            </div>

          </div>

        </div>

        <div class="slider-content">
          <ParagraphAtom align="center" color="dark" size="intermediate" weight="regular" [text]="cmsData!.data.slider_step_three_subtitle"></ParagraphAtom>
        </div>

        <ul>
          <li>
            <a href="javascript:void(0);" (click)="goToStep(1)">
              <Image type="icon" width="8px" image="ellipsis-grey"></Image>
            </a>
          </li>
          <li>
            <a href="javascript:void(0);" (click)="goToStep(2)">
              <Image type="icon" width="8px" image="ellipsis-grey"></Image>
            </a>
          </li>
          <li>
            <a href="javascript:void(0);" (click)="goToStep(3)">
              <Image type="icon" width="8px" image="ellipsis-yellow"></Image>
            </a>
          </li>
        </ul>

      </div>
    </div>

  <!-- PRO -->
  <div id="chargingPro" class="offer-pro" *ngIf="cmsData!.data && (cmsData!.data.professional_title?.trim() !== '' || cmsData!.data.professional_text?.trim() !== '' || cmsData!.data.professional_yellow_btn_text?.trim() !== '' || cmsData!.data.professional_light_btn_text?.trim() !== '' || cmsData!.data.professional_img?.url?.trim() !== '')">
    <div>
      <TitleAtom [mobileSize]="22" color="light" weight="semi-bold" size="x-large" [text]="cmsData!.data.professional_title"></TitleAtom>
      <ParagraphAtom color="light" size="intermediate" weight="regular" [text]="cmsData!.data.professional_text"></ParagraphAtom>
      <div>
        <ButtonAtom size="default" [style]="'basic'" color="yellow" [text]="cmsData!.data.professional_yellow_btn_text" (click)="hideContactModal = false"></ButtonAtom>
        <ButtonAtom size="default" [style]="'stroked'" color="light" [text]="cmsData!.data.professional_light_btn_text" [href]="cmsData!.data.professional_light_btn_link.url" ></ButtonAtom>
      </div>
    </div>

    <Image borderRadius="radius" type="image" width="500px" mobileWidth="100%" [imageExternal]="cmsData!.data.professional_img.url"></Image>
  </div>

  <!-- FAQ -->
  <div id="chargingFaq" class="faq-container" *ngIf="(cmsData!.data &&
           (cmsData!.data.faq_title?.trim() !== '' ||
            cmsData!.data.faq_subtitle?.trim() !== '')) &&
           (faqs && faqs.length > 0)">
    <TitleAtom align="center" [mobileSize]="16" size="tiny" weight="semi-bold" color="orange" [text]="cmsData!.data.faq_title"></TitleAtom>
    <TitleAtom [mobileSize]="22" type="h2" size="x-large" weight="semi-bold" color="dark" [text]="cmsData!.data.faq_subtitle"></TitleAtom>
    <ul>
      <li *ngFor="let faq of faqs">
        <Faq title="{{ faq.data.title }}" [text]="faq.data.body"></Faq>
      </li>
    </ul>
  </div>

  <!-- FOOTER -->
  <div class="footer-container">
    <Footer></Footer>
  </div>

</div>

<ContactMessage title="Demander un devis gratuitement" btnText="Demander un devis gratuitement" userType="particulier" type="modal" [(isHidden)]="hideContactModal"></ContactMessage>
